<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Note  -->
          <b-col cols="6">
            <b-form-group id="input-note">
              <label for="noteInput">Note:</label>
              <b-form-input
                name="note"
                v-model="editedItem.note"
                type="text"
                aria-describedby="input-note"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <!-- status -->
          <b-col cols="6">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select
                v-model="filter_status"
                :options="statusList"
                name="Status"
                data-vv-as="Status"
                :class="{ input: true, 'is-danger': errors.has('Status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template>
              </b-form-select>
              <span v-show="errors.has('Status')" class="is-danger-text position-relative">{{
                errors.first('Status')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- User Name  -->
          <b-col cols="6">
            <b-form-group>
              <label for="usernameInput">User Name:</label>
              <b-form-input disabled v-model="editedItem.username" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- payment_method  -->
          <b-col cols="6">
            <b-form-group>
              <label for="payment_methodInput">Payment method:</label>
              <b-form-input disabled v-model="editedItem.method" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- requested_at  -->
          <b-col cols="6">
            <b-form-group>
              <label for="requested_at_input">Requested At:</label>
              <b-form-input
                id="requested_at_input"
                disabled
                v-model="editedItem.requested_at"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- withdraw_amount  -->
          <b-col cols="6">
            <b-form-group>
              <label for="withdraw_amount_input">Withdraw Amount:</label>
              <b-form-input
                id="withdraw_amount_input"
                disabled
                v-model="editedItem.withdraw_amount"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label for="withdraw_amount_input">Actual Withdraw Amount:</label>
              <b-form-input
                id="withdraw_amount_input"
                disabled
                v-model="editedItem.actual_withdraw_amount"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <label for="withdraw_amount_input">Withdraw Fee:</label>
              <b-form-input
                id="withdraw_amount_input"
                disabled
                v-model="editedItem.withdraw_fee"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <!-- withdraw_info  -->
          <b-col cols="12">
            <b-form-group>
              <label for="withdraw_info">Withdraw Info:</label>
              <b-textarea id="withdraw_info" disabled :value="withdrawInfo" type="textarea" rows="8" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Withdraw Transactions List</h4>
      </b-col>
      <!-- Filter UserName -->
      <b-col cols="2">
        <b-form-group id="input-name-filter" label="UserName" label-for="username-filter">
          <b-form-input
            id="username-filter"
            v-model="filter_username"
            type="text"
            placeholder="Search username ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- Filter Id -->
      <b-col>
        <b-form-group id="input-id-filter" label="Request Id" label-for="id-filter">
          <b-form-input
            id="id-filter"
            v-model="filter_request_id"
            type="text"
            placeholder="Search id ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- filter date from -->
      <b-col cols="2">
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_from_date" :config="dpOptions" @dp-change="fetchData"></datePicker>
        </b-form-group>
      </b-col>
      <!-- filter date to -->
      <b-col cols="2">
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_to_date" :config="dpOptions" @dp-change="fetchData"></datePicker>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group id="input-payment-filter" label="Payment method" label-for="payment-filter">
          <b-form-select id="payment-filter" v-model="filter_payment_id" :options="allPaymentList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Filter Active -->
      <b-col>
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- download -->
      <b-col cols="2" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="downloadFile">Download</b-button>
      </b-col>
      <!-- choose file -->
      <b-col cols="10">
        <b-form-group id="input-file" label="File:" label-for="file">
          <b-form-file
            v-model="file_upload"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </b-col>
      <!-- upload -->
      <b-col class="btnAdd">
        <b-button variant="primary" class="float-right" @click="uploadFile">Upload</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center orderTable"
    >
      <template v-slot:cell(withdraw_amount)="item">{{ formatPrice(item.item.withdraw_amount) }}</template>
      <template v-slot:cell(actual_withdraw_amount)="item">{{
        formatPrice(item.item.actual_withdraw_amount)
      }}</template>
      <template v-slot:cell(withdraw_free)="item">{{ formatPrice(item.item.withdraw_free) }}</template>

      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Success</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 2" variant="danger">Rejected</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Pending</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success" @click="editItem(item.item)">mdi-pencil</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline id="input-per-page" style="margin-right: 20px; margin-bottom: 0">
          <label for="per-page" class="mr-sm-2">Rows per page: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { exportRequest, importFile } from '@/utils/download.js';
import { formatDateForView } from '@/utils/time.js';

export default {
  name: 'Withdraw Transaction',
  components: { datePicker },
  data() {
    return {
      search: '',
      isBusy: false,
      filter_username: '',
      filter_method_id: null,
      filter_status: null,
      filter_request_id: '',
      filter_from_date: '',
      filter_to_date: '',
      filter_payment_id: 7,
      statusList: [
        { value: 1, text: 'Success' },
        { value: 2, text: 'Rejected' },
        { value: 0, text: 'Pending' }
      ],
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Withdraw Id', key: 'display_withdraw_id' },
        { text: 'User Name', key: 'username' },
        { text: 'Payment_Method', key: 'method' },
        // { text: "Withdraw Info", key: "withdraw_info" },
        { text: 'Withdraw Amount', key: 'withdraw_amount' },
        { text: 'Actual Withdraw Amount', key: 'actual_withdraw_amount' },
        { text: 'Fee', key: 'withdraw_fee' },
        { text: 'Note', key: 'note' },
        { text: 'Requested At', key: 'requested_at' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allPaymentList: [],
      selectedPayment: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        account_id: '',
        withdraw_amount: '',
        method_id: '',
        status: '',
        requested_at: '',
        display_withdraw_id: '',
        actual_withdraw_amount: '',
        withdraw_fee: '',
        withdraw_info: '',
        note: '',
        username: '',
        method: ''
      },
      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      file_upload: null
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    withdrawInfo() {
      const withdrawInfo = [];
      for (let [key, value] of Object.entries(this.editedItem.withdraw_info)) {
        withdrawInfo.push(`${key} : ${value}`);
      }

      return withdrawInfo.join('\n');
    },
    isDisableBtn() {
      return this.errors.has('Status');
    },
    isBank() {
      return this.filter_payment_id == 7;
    },
    role() {
      return this.$store.getters.currentUser.data.role;
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Withdraw Transaction List' }]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsWithdraw = ApiService.get(
        'withdraw-transactions',
        `?requestid=${this.filter_request_id}&from=${this.filter_from_date || ''}&to=${
          this.filter_to_date || ''
        }&username=${this.filter_username}&status=${this.filter_status == null ? '' : this.filter_status}&method_id=${
          this.filter_method_id || ''
        }&offset=${offset}&limit=${limit}&payment_id=${this.filter_payment_id || 0}`
      );
      const allPayments = ApiService.get('allpayment');
      axios
        .all([adsWithdraw, allPayments])
        .then(
          axios.spread((...response) => {
            const adsWithdrawRes = response[0];
            const allPaymentRes = response[1];
            this.items = adsWithdrawRes.data.data.withdrawList.map((ads) => {
              return {
                ...ads,
                requested_at: formatDateForView(new Date(ads.requested_at).toISOString())
              };
            });
            this.rows = adsWithdrawRes.data.data.total;
            this.allPaymentList = allPaymentRes.data.data;
            // .filter(
            //   (payment) => this.role != 'accountant' || payment.handler == 'bank'
            // );
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    editItem: function (item) {
      this.dialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.modalTitle = `Update Withdraw Transaction #${item.display_withdraw_id}`;
      this.filter_status = item.status;
    },

    close() {
      this.dialog = false;
      this.editedItem = {
        id: '',
        account_id: '',
        withdraw_amount: '',
        method_id: '',
        status: '',
        requested_at: '',
        display_withdraw_id: '',
        withdraw_info: '',
        actual_withdraw_amount: '',
        withdraw_fee: '',
        note: '',
        username: '',
        method: ''
      };
      this.editedIndex = -1;
      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Get new brand/cate/vendor name for data-table
      let productData = {
        note: this.editedItem.note || undefined,
        status: this.filter_status
      };
      if (this.editedIndex > -1) {
        // Update roles
        axios({
          method: 'PUT',
          url: `withdraw-transactions/${this.items[this.editedIndex].id}`,
          data: productData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Withdraw Transactions updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    },
    downloadFile() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      exportRequest(
        `/exports/withdraw-transactions?requestid=${this.filter_request_id}&from=${this.filter_from_date}&to=${
          this.filter_to_date
        }&user=${this.filter_username}&status=${
          this.filter_status == null ? 0 : this.filter_status
        }&offset=${offset}&limit=${limit}`
      );
      this.isBusy = false;
    },
    uploadFile() {
      let file = this.file_upload;
      if (file) {
        importFile(`/imports/withdraw-transactions`, file)
          .then((resp) => {
            if (resp) {
              this.$bvToast.toast('File uploadted successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.file_upload = null;
            }
          })
          .catch((err) => {
            this.$bvToast.toast(`${err}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
            this.isBusy = false;
          });
      } else {
        this.$bvToast.toast(`Please, choose your file!`, {
          title: null,
          variant: 'danger',
          autoHideDelay: 3000,
          noCloseButton: true
        });
      }
    }
  }
};
</script>
